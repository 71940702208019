import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Button, Flex, Link } from "@chakra-ui/core"
import Img from "gatsby-image"

const Header = () => {
    const {
      contentfulSiteMetaData: { logo },
    } = useStaticQuery(HeaderQuery)
  return (
    <Flex
      position="fixed"
      h="60px"
      top={0}
      left={0}
      right={0}
      zIndex={999}
      bgColor="rgba(0,0,0,.4)"
    >
      <Flex
        w="100%"
        h="100%"
        py="20px"
        maxW="1140px"
        px="15px"
        color="white"
        justifyContent="space-between"
        alignItems="center"
        mx="auto"
      >
        <Box w={{ base: "200px", lg: "300px" }}>
          <Img fluid={logo.fluid} objectFit="cover" objectPosition="50% 50%" />
        </Box>
        <Box textAlign="right" flex={{ base: 1, md: 2 }}>
          <Link
            mr="25px"
            href="#services"
            display={{ base: "none", md: "inline-block" }}
          >
            Services
          </Link>
          <Link
            mr="25px"
            href="#testimonials"
            display={{ base: "none", md: "inline-block" }}
          >
            Testimonials
          </Link>
          <Link href="#contact">
            <Button size="md" colorScheme="blue">
              Book Now
            </Button>
          </Link>
        </Box>
      </Flex>
    </Flex>
  )
}

export default Header;

const HeaderQuery = graphql`
  query HeaderQuery {
    contentfulSiteMetaData {
      logo {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
    }
  }
`