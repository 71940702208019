import React from "react"
import { Box, Heading } from "@chakra-ui/core"
import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  const {
    contentfulSiteMetaData: { authorName },
  } = useStaticQuery(FooterQuery);
  return (
    <Box p="20px" bgColor="gray.700" textAlign="center" color="white">
      <Box>
        <Heading size="sm">
          &copy;{new Date().getFullYear()} - {authorName}
        </Heading>
      </Box>
    </Box>
  )
}

export default Footer

const FooterQuery = graphql`
  query FooterQuery {
    contentfulSiteMetaData {
      authorName
    }
  }
`