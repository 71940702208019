import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({title = null, pageKeywords = [], meta = []}) => {
  const {
    contentfulSiteMetaData: {
      authorName,
      favicon,
      siteName,
      siteUrl,
      keywords,
      siteDescription,
      socialSharingImage,
    },
  } = useStaticQuery(detailsQuery)
  let siteKeywords = keywords.concat(pageKeywords).join(',');
  let siteTitle = title ? `${title} | ${siteName}` : siteName;
  return (
    <Helmet
      title={siteTitle}
      meta={[
        { property: `description`, content: siteDescription },
        { property: `keywords`, content: siteKeywords },
        { property: `author`, content: authorName },
        { property: `og:type`, content: "website" },
        { property: `og:title`, content: siteTitle },
        { property: `og:description`, content: siteDescription },
        { property: `fb:pages`, content: "108555190615384" },
        {
          property: `og:image`,
          content: `https:${socialSharingImage.file.url}`,
        },
        { property: `og:url`, content: siteUrl },
        { property: `twitter:title`, content: siteTitle },
        { property: `twitter:description`, content: siteDescription },
        { property: `twitter:url`, content: siteUrl },
        {
          property: `twitter:image`,
          content: `https:${socialSharingImage.file.url}`,
        },
        { property: `twitter:card`, content: "summary_large_image" },
        { property: `apple-mobile-web-app-capable`, content: "yes" },
        {
          property: `apple-mobile-web-app-status-bar-style`,
          content: "#000000",
        },
        { property: `application-name`, content: siteTitle },
      ].concat(meta)}
    >
      <link rel="shortcut icon" href={`${favicon.file.url}?w=64`}></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;400;700&display=swap"
        rel="stylesheet"
      ></link>
      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
    </Helmet>
  )
}

export default SEO

const detailsQuery = graphql`
  query SiteMetaDataQuery {
  contentfulSiteMetaData {
    siteName
    siteUrl
    keywords
    siteDescription
    socialSharingImage {
      file {
        url
      }
    }
    favicon {
      file {
        url
      }
    }
  }
}
`