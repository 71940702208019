import React from "react"

import Header from "./header"
import Footer from "./footer"

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children }) => (
  <>
    <Header />
    <div>
      <main>{children}</main>
    </div>
    <Footer />
  </>
)

export default Layout
